import React from 'react';
import {successfulModal} from '../../../../assets/images/images';
import {Modal, PrimaryButton} from '../../../../components';
import {getUserProfile} from '../../../../store/actions';
import {useDispatch} from 'react-redux';

export const Successful2FA = ({setOpen, open}) => {
  const dispatch = useDispatch();
  return (
    <Modal
      show={open}
      onClose={setOpen}
      extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
      modalTitle="2FA"
    >
      <div className="mt-6">
        <h2 className="text-grey font-medium text-base text-center">
          2-Factor Authorization successfully
        </h2>
        <p className="text-body text-sm max-w-[350px] m-auto my-2">
          You have successfully set up your 2 factor authentication and can now
          go back to the dashboard
        </p>
        <div className="w-full flex justify-center mb-8">
          <img src={successfulModal} alt="success" />
        </div>

        <PrimaryButton
          buttonText="Done"
          className="w-full"
          onClick={() => {
            setOpen(false);
            dispatch(getUserProfile());
          }}
        />
      </div>
    </Modal>
  );
};
