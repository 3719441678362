import React from 'react';

export const PinInput = ({
  pin,
  onChange,
  onKeyDown,
  onPaste,
  pinRefs,
  show,
  rounded,
  justify = 'justify-center ',
}) => (
  <div className={`flex mt-10 items-center gap-3 ${justify}`}>
    {pin.map((digit, index) => (
      <div key={index} className="relative">
        <input
          type="text"
          inputMode="numeric"
          value={digit}
          onChange={e => onChange(e, index)}
          onKeyDown={e => onKeyDown(e, index)}
          onPaste={e => onPaste(e, index)}
          maxLength={1}
          className={`w-16 h-16 text-center  ${
            rounded ? rounded : 'rounded-xl'
          } text-transparent caret-black`}
          ref={el => (pinRefs.current[index] = el)}
        />
        {!show ? (
          <span
            className={`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-3 h-3  ${
              rounded ? rounded : 'rounded-full'
            } ${digit ? 'bg-black' : 'bg-transparent'} pointer-events-none`}
          />
        ) : (
          <span
            className={`absolute left-7 top-6 text-xl -translate-x-1/2 -translate-y-1/2 w-3 h-3 ${
              rounded ? rounded : 'rounded'
            }`}
          >
            {digit}
          </span>
        )}
      </div>
    ))}
  </div>
);
