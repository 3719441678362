import {api} from '../../helpers';

function setUp2FA() {
  return api.post(`/users/2fa/setup`).then(res => res.data);
}
function verify2FA(data) {
  return api.post(`/users/2fa/verify`, data).then(res => res.data);
}
function remove2FA(data) {
  return api.post(`/users/2fa/remove`, data).then(res => res.data);
}

export {setUp2FA, verify2FA, remove2FA};
