/* eslint-disable no-shadow */
import React, {Fragment} from 'react';
import {Listbox, Transition} from '@headlessui/react';
import {dropdownFillWhite, dropdownGrey} from '../assets/images/images';
import PuffLoader from 'react-spinners/PuffLoader';
import {css} from '@emotion/react';
import {useMediaQuery} from '../hooks';

export default function SingleDropdown({
  dropdownData,
  selected,
  setSelected,
  spanText,
  width,
  children,
  loading,
}) {
  const override = css`
    border-color: #ffffff;
    position: absolute;
    background: transparent;
    padding-right: 50px;
  `;
  const media = useMediaQuery('(min-width: 768px)');
  return (
    <div className="max-w-72">
      <Listbox value={selected} onChange={setSelected}>
        <div className="relative">
          <Listbox.Button className="test relative w-full flex gap-[37px] items-center justify-between rounded-t-lg md:rounded-none cursor-pointer bg-white md:bg-brandBlue py-3 px-4 md:py-5 md:px-6 text-left focus:outline focus:outline-1 focus:outline-white">
            {loading ? (
              <div className="flex items-center justify-center ml-5 ">
                <PuffLoader css={override} size={35} color="#ffffff" />
              </div>
            ) : (
              <p className="font-medium text-grey md:text-white truncate">
                {spanText}
              </p>
            )}
            <img src={media ? dropdownFillWhite : dropdownGrey} alt="" />
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className={`md:absolute md:right-0 z-10 md:mt-1 flex flex-col rounded-b-lg bg-white md:bg-brandBlue py-3 px-4 shadow ${
                width ?? 'w-[214px]'
              }`}
            >
              <p className="hidden mb-4 font-medium md:block">
                Switch to a different company
              </p>
              {dropdownData?.map((data, idx) => (
                <Listbox.Option
                  key={idx}
                  className={({active, selected}) =>
                    `relative flex items-center justify-between cursor-pointer select-none py-2 pl-6 rounded ${
                      active
                        ? 'bg-brandBlue md:bg-brandBlue md:opacity-80 outline outline-1 outline-white80 rounded'
                        : ''
                    } ${
                      selected
                        ? 'bg-brandBlue md:bg-brandBlue md:opacity-80 outline outline-1 outline-white80 rounded'
                        : ''
                    }`
                  }
                  value={data}
                >
                  <span className="font-medium text-body md:text-white truncate">
                    {data.name}
                  </span>
                </Listbox.Option>
              ))}
              {children}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
