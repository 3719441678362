import React, {useRef, useState, useCallback, useEffect, useMemo} from 'react';

import {Modal} from '../../../../components';
import {PinInput} from '../../../../components/PinInput';
import {baasLock} from '../../../../assets/images/images';
import {useMutation} from 'react-query';
import {remove2FA, verify2FA} from '../../../../requests/queries/settings';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';

const OTP_LENGTH = 6;
const OTP_ARRAY = Array(OTP_LENGTH).fill('');

export const ValidateOTPModal = ({setOpen, open, onComplete, isEnabled}) => {
  const [otp, setOtp] = useState(OTP_ARRAY);
  const otpRefs = useRef([]);
  const {user} = useSelector(state => state?.auth);

  const {mutate, isLoading} = useMutation(verify2FA, {
    onSuccess: data => {
      onComplete(data);
      setOpen(false);
      setOtp(OTP_ARRAY);
      toast.success(data?.message);
    },
    onError: error => {
      toast.error(error.response.data.error);
      setOtp(OTP_ARRAY);
    },
  });
  const {mutate: remove2FAMutation, isLoading: removeLoading} = useMutation(
    remove2FA,
    {
      onSuccess: data => {
        onComplete(data);
        toast.success(data?.message);
        setOtp(OTP_ARRAY);
      },
      onError: error => {
        toast.error(error.response.data.error);
        setOtp([]);
      },
    },
  );

  const handlePaste = useCallback((e, currentPin, setCurrentPin) => {
    e.preventDefault();
    const pastedData = e.clipboardData
      .getData('text')
      .replace(/\D/g, '')
      .slice(0, OTP_LENGTH);
    const newPin = [...currentPin];
    for (let i = 0; i < pastedData?.length; i++) {
      newPin[i] = pastedData[i];
    }
    setCurrentPin(newPin);
    if (pastedData?.length === OTP_LENGTH) {
      otpRefs.current[OTP_LENGTH - 1]?.focus();
    } else {
      otpRefs.current[pastedData?.length]?.focus();
    }
  }, []);
  const handleInputChange = useCallback(
    (e, index, currentPin, setCurrentPin) => {
      const value = e.target.value.replace(/\D/g, '');
      if (value.length <= 1) {
        const newPin = [...currentPin];
        newPin[index] = value;
        setCurrentPin(newPin);

        if (value && index < OTP_LENGTH - 1) {
          otpRefs.current[index + 1]?.focus();
        }
      }
    },
    [],
  );
  useEffect(() => {
    otpRefs.current[0]?.focus();
  }, []);

  const handleKeyDown = useCallback((e, index, currentPin) => {
    if (e.key === 'Backspace' && !currentPin[index] && index > 0) {
      otpRefs.current[index - 1]?.focus();
    }
  }, []);
  const isValid = useMemo(() => otp.join('').length === OTP_LENGTH, [otp]);

  const handleMutate2FA = useCallback(() => {
    try {
      const data = {
        id: user?.id,
        token: otp?.join(''),
      };
      isEnabled ? remove2FAMutation(data) : mutate(data);
    } catch (e) {
      console.log(e);
    }
  }, [isEnabled, mutate, otp, remove2FAMutation, user?.id]);

  useEffect(() => {
    if (isValid) {
      handleMutate2FA();
    }
  }, [handleMutate2FA, isValid]);

  return (
    <Modal
      show={open}
      onClose={setOpen}
      extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
      modalTitle="Setup 2FA - Scan QR code"
    >
      {(isLoading || removeLoading) && (
        <div className="absolute flex flex- justify-center left-0 h-full opacity-50 z-20 top-0 items-center   mb-2 bg-white w-full">
          <div className="spinner "></div>
        </div>
      )}
      <div
        className="mt-6 relative"
        style={{
          opacity: isLoading || removeLoading ? 0.2 : 1,
        }}
      >
        <div className="flex flex-col items-center gap-3">
          <img src={baasLock} alt="lock icon" />
          <p className="font-inter text-base text-[#4F555F] text-center tracking-[-1%] max-w-[385px] m-auto">
            Validate 2-Factor Authentication (2FA) OTP
          </p>
          <span className="text-sm text-grey-500 w-[300px]">
            A code has been generated on your authenticator app, enter the OTP
            code below
          </span>
        </div>

        <PinInput
          pin={otp}
          onChange={(e, index) => handleInputChange(e, index, otp, setOtp)}
          onKeyDown={(e, index) => handleKeyDown(e, index, otp)}
          onPaste={e => handlePaste(e, otp, setOtp)}
          pinRefs={otpRefs}
          show
          rounded="rounded"
        />

        {/*<div className="flex items-center mt-6">*/}
        {/*  <PrimaryButton*/}
        {/*    buttonText="Validate OTP"*/}
        {/*    className="w-full rounded-lg"*/}
        {/*    onClick={handleMutate2FA}*/}
        {/*    disabled={!isValid}*/}
        {/*    loading={isLoading || removeLoading}*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
    </Modal>
  );
};
