import pdfFonts from 'pdfmake/build/vfs_fonts';
import pdfMake from 'pdfmake/build/pdfmake';
import {isObjectEmpty} from '../../helpers/isObjectEmpty';
import {capitalizeEveryWord} from '../../helpers';
import {horizontalLine} from '../../helpers/pdfUtils';
import {pushObjectsIntoArray} from '../../helpers/pushOnlyFourItemsArrayOfArrays';

pdfMake.vfs = pdfFonts?.pdfMake?.vfs;

const generateHeader = (companyName, page = 'AML Screening') => {
  const header = [{text: page ?? 'AML Screening', style: 'type'}];

  if (companyName) {
    header.push({
      text: `Requested by ${companyName}`,
      style: {alignment: 'center', fontSize: 11, color: '#4A4A68'},
      margin: [0, 0, 0, 30],
    });
  }

  return header;
};
const generateSectionHeader = title => [
  {
    text: title?.toUpperCase(),
    margin: [0, 20, 0, 7],
    style: {bold: true, fontSize: 11},
  },
  horizontalLine(5),
];

export const generateAmlPDF = ({
  pdfData = [],
  pdfArray,
  companyName = '',
  page = 'AML Screening',
}) => {
  const generateDocInfo = pdfData
    ?.filter(doc => !isObjectEmpty(doc.data))
    ?.map(doc => doc);

  const header = generateHeader(companyName, page);

  const content = [...header];

  generateDocInfo.forEach(section => {
    const dataRows = [];
    Object.entries(section.data).forEach(([key, value]) => {
      if (value && typeof value === 'string') {
        const pair = [
          {
            width: 200,
            bold: true,
            margin: [0, 0, 20, 10],
            style: {fontSize: 9},
            text: capitalizeEveryWord(
              `${key}:`.replaceAll('_', ' '),
            )?.toUpperCase(),
          },
          {
            width: '*',
            margin: [0, 0, 20, 0],
            style: {color: '#4A4A68', fontSize: 10},
            text: value && value?.toUpperCase()?.replaceAll(',', ', '),
          },
        ];

        dataRows.push(pair);
      }
    });

    if (section.data && Object.values(section?.data).some(r => r)) {
      const sectionHeader = generateSectionHeader(section.title);
      content.push(sectionHeader);
    }
    if (['Profile', 'Document'].includes(section?.title)) {
      const profile = dataRows.map(row => ({
        style: 'columnStyle',
        layout: 'noBorders',
        table: {widths: [100, '*'], body: [...[row]]},
      }));

      const dataColumns = pushObjectsIntoArray(profile);

      content.push(dataColumns);
    } else if (section?.title === 'Scores') {
      const score = dataRows.map(row => ({
        style: 'columnStyle',
        layout: 'noBorders',
        table: {widths: [100, '*'], body: [...[row]]},
      }));
      content.push({columns: [...score]});
      content.push({
        text: 'Match Result: This is the number of search result associated with user name',
        margin: [0, 0, 0, 10],
        style: {fontSize: 10, color: '#4A4A68'},
      });
      content.push({
        text: `Match score: This score is pulled from the API, it is always over 100%`,
        margin: [0, 0, 0, 10],
        style: {fontSize: 10, color: '#4A4A68'},
      });
    }
  });

  if (pdfArray?.length > 0) {
    pdfArray.forEach(section => {
      if (section?.title && section?.data?.length > 0) {
        if (
          section?.data?.map(d => Object.values(d).some(r => r)).filter(Boolean)
            ?.length > 0
        ) {
          const pdfHeader = generateSectionHeader(section?.title);
          content.push(pdfHeader);
        }
        if (section?.title === 'Remarks') {
          section?.data?.forEach(item => {
            const key = Object.keys(item)[0];
            const value = item[key];
            content.push({
              text: value,
              margin: [0, 0, 0, 10],
              style: {fontSize: 10, color: '#4A4A68'},
            });
          });
        } else if (section?.title === 'Media weblinks') {
          section?.data?.forEach(item => {
            const key = Object.keys(item)[0];
            const value = item[key];
            content.push({
              text: value,
              margin: [0, 0, 0, 10],
              style: {fontSize: 10, color: '#4A4A68'},
            });
          });
        } else if (section?.title === 'Related Urls') {
          section?.data?.forEach(item => {
            const key = Object.keys(item)[0];
            const value = item[key];
            content.push({
              text: value,
              margin: [0, 0, 0, 10],
              style: {fontSize: 10, color: '#4A4A68'},
            });
          });
        } else if (section?.title === 'Sources') {
          section?.data?.forEach(item => {
            const key = Object.keys(item)[0];
            const value = item[key];
            content.push([
              {
                text: item?.name,
                margin: [0, 0, 0, 0],
                style: {fontSize: 12, color: '#2e2e37'},
              },
              {
                text: value,
                margin: [0, 0, 0, 10],
                style: {fontSize: 9, color: '#4A4A68'},
              },
            ]);
          });
        } else if (section?.title === 'Relationships') {
          section?.data?.forEach(item => {
            const key = Object.keys(item)[0];
            const value = item[key];
            const pair = [
              {
                width: 200,
                bold: true,
                margin: [0, 0, 20, 10],
                style: {fontSize: 11},
                text: capitalizeEveryWord(
                  `${key}:`.replaceAll('_', ' '),
                )?.toUpperCase(),
              },
              {
                width: '*',
                margin: [0, 0, 20, 0],
                style: {color: '#4A4A68', fontSize: 11},
                text: value?.toUpperCase(),
              },
            ];
            content.push([
              {
                style: 'columnStyle',
                layout: 'noBorders',
                table: {widths: [100, '*'], body: [...[pair]]},
              },
            ]);
          });
        }
      }
    });
  }

  return content;
};

export const generateGlobalCheckPDF = ({
  pdfData = [],
  pdfArray,
  companyName,
}) => {
  const generateDocInfo = pdfData
    ?.filter(doc => !isObjectEmpty(doc.data))
    ?.map(doc => doc);

  const header = generateHeader(companyName, 'Global Check');

  const content = [...header];

  generateDocInfo.forEach(section => {
    const dataRows = [];
    Object.entries(section.data).forEach(([key, value]) => {
      if (value && typeof value === 'string') {
        const pair = [
          {
            width: 200,
            bold: true,
            margin: [0, 0, 20, 10],
            style: {fontSize: 9},
            text: capitalizeEveryWord(
              `${key}:`.replaceAll('_', ' '),
            )?.toUpperCase(),
          },
          {
            width: '*',
            margin: [0, 0, 20, 0],
            style: {color: '#4A4A68', fontSize: 10},
            text: value && value?.toUpperCase()?.replaceAll(',', ', '),
          },
        ];

        dataRows.push(pair);
      }
    });

    if (section.data && Object.values(section?.data).some(r => r)) {
      const sectionHeader = generateSectionHeader(section.title);
      content.push(sectionHeader);
    }
    if (section?.title === 'Profile') {
      const profile = dataRows.map(row => ({
        style: 'columnStyle',
        layout: 'noBorders',
        table: {widths: [100, '*'], body: [...[row]]},
      }));

      const dataColumns = pushObjectsIntoArray(profile);

      content.push(dataColumns);
    } else if (section?.title === 'Country') {
      const country = dataRows.map(row => ({
        style: 'columnStyle',
        layout: 'noBorders',
        table: {widths: [100, '*'], body: [...[row]]},
      }));
      const dataColumns = pushObjectsIntoArray(country);
      content.push(dataColumns);
    } else if (section?.title === 'Contacts') {
      const contact = dataRows.map(row => ({
        style: 'columnStyle',
        layout: 'noBorders',
        table: {widths: [100, '*'], body: [...[row]]},
      }));
      const dataColumns = pushObjectsIntoArray(contact);
      content.push(dataColumns);
    }
  });

  if (pdfArray?.length > 0) {
    pdfArray.forEach(section => {
      if (section?.title && section?.data?.length > 0) {
        if (
          section?.data?.map(d => Object.values(d).some(r => r)).filter(Boolean)
            ?.length > 0
        ) {
          const pdfHeader = generateSectionHeader(section?.title);
          content.push(pdfHeader);
        }
        if (section?.title === 'Persons') {
          section?.data?.forEach(person => {
            content.push(generateSectionHeader(person.name));

            const personDataRows = Object.entries(person.data).map(
              ([key, value]) => {
                const displayValue =
                  typeof value === 'string' ? value.toUpperCase() : value;

                return [
                  {
                    width: 200,
                    bold: true,
                    margin: [0, 0, 20, 10],
                    style: {fontSize: 11},
                    text: capitalizeEveryWord(
                      `${key}:`.replaceAll('_', ' '),
                    )?.toUpperCase(),
                  },
                  {
                    width: '*',
                    margin: [0, 0, 20, 0],
                    style: {color: '#4A4A68', fontSize: 11},
                    text: displayValue,
                  },
                ];
              },
            );

            const personTable = {
              style: 'columnStyle',
              layout: 'noBorders',
              table: {
                widths: [100, '*'],
                body: personDataRows,
              },
            };

            content.push(personTable);
          });
        } else if (
          section?.title === 'Activities' &&
          section?.data?.length > 0
        ) {
          section?.data?.forEach(item => {
            const key = Object.keys(item)[0];
            const value = item[key];
            content.push({
              text: value,
              margin: [0, 0, 0, 10],
              style: {fontSize: 10, color: '#4A4A68'},
            });
          });
        }
      }
    });
  }

  return content;
};
