import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import * as Actions from '../../store/actions';
import {Loader} from '../../components';
import {corruptedFile, smiley} from '../../assets/images/images';

function GlobalSearchResultsModal({
  setOpen,
  lookup: {lookup, loading},
  changeLookupPage,
}) {
  const SearchResults = lookup?.global_search?.entity;

  return (
    <>
      {loading ? (
        <Loader height={25} />
      ) : (
        <div className="mt-6 flex flex-col gap-4">
          {SearchResults?.length > 0 ? (
            SearchResults?.map((result, index) => (
              <Link
                to={`/easy-lookup/business/global-search/result/${result.internationalNumber}`}
                key={index}
                className="p-6 bg-white80 rounded flex items-center justify-between w-full card-link"
                onClick={() => {
                  setOpen(false);
                  changeLookupPage(1);
                }}
              >
                <div className="flex items-center gap-2">
                  <img src={smiley} alt="" width={24} height={24} />
                  <p className="text-base text-grey ">
                    {result.name || result?.Name}
                  </p>
                </div>
                <p className="text-sm text-grey pr-4">
                  {result.match_type || result?.NameMatchScore}
                </p>
              </Link>
            ))
          ) : (
            <div className="p-4  w-full ">
              <div className="flex flex-col items-center justify-center py-12 mt-6 bg-white80">
                <img src={corruptedFile} alt="" width={48} height={48} />
                <p className="max-w-[179px] mt-2 text-sm text-center text-body">
                  We did not find any information on this company
                </p>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default connect(state => state, Actions)(GlobalSearchResultsModal);
