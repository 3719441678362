import {logo} from '../assets/images/images';

export const convertSVGToBase64 = () =>
  new Promise((resolve, reject) => {
    const svgPath = logo;
    const img = new Image();

    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);

      const base64URL = canvas.toDataURL('image/png');
      resolve(base64URL);
    };

    img.onerror = error => {
      reject(error);
    };

    img.src = svgPath;
  });

export const imageUrlToBase64 = async lookupImage => {
  if (!lookupImage?.startsWith('http')) return lookupImage;
  try {
    const cleanUrl = encodeURI(lookupImage);
    const response = await fetch(cleanUrl);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error('Error fetching image:', error);
    return null;
  }
};
