import React from 'react';
import {affiliateskeysOfInterest, ignoreKeys} from '../../LookupUtils';
import {checkKey} from '../../../../helpers/constants/ckeckLookupKeys';
import {chevronRight} from '../../../../assets/images/images';
import moment from 'moment';
export const LookupContent = ({
  env,
  lookupImage,
  sandbox_dummy,
  formatKey,
  lastNames,
  firstNames,
  companyNames,
  lookupData,
  lookupKeys,
  showAffiliateDetails,
  setShowAffiliateDetails,
}) => (
  <div className="">
    <div className="mt-8 flex flex-col items-center">
      {env !== 'Sandbox' ? (
        <>
          {lookupImage ? (
            <img
              src={
                lookupImage?.startsWith('https')
                  ? lookupImage
                  : `data:image/png;base64,${lookupImage}`
              }
              alt="User Image"
              width={80}
              height={80}
              className="text-small"
            />
          ) : (
            <div className="h-[110px] w-[110px] rounded-full bg-brandBlue40  text-brandBlue flex justify-center items-center text-xxl ">
              {lastNames.map(
                name => `${lookupData[name]?.substr(0, 1) || '-'}`,
              )}
              {firstNames.map(
                name =>
                  `${
                    companyNames.includes(name)
                      ? lookupData[name]?.split(' ')[1]?.substr(0, 1)
                      : lookupData[name]?.substr(0, 1) || '-'
                  }`,
              )}
            </div>
          )}
        </>
      ) : (
        <img
          src={sandbox_dummy}
          alt="Photo"
          width={80}
          height={80}
          className="text-small"
        />
      )}

      <p className="mt-4 text-sm text-grey font-medium">
        {/* {lastNames.map(
          name =>
            `${
              companyNames.includes(name)
                ? lookupData[name]?.split(' ')[0]
                : lookupData[name] || '-'
            } `,
        )} */}
        {firstNames.map(
          name =>
            `${
              ['Name_of_Company'].includes(name)
                ? lookupData[name]?.split(' ')?.slice(1)?.join(' ')
                : lookupData[name] || '-'
            }`,
        )}
      </p>
    </div>

    <div className="mt-8 px-4 py-4 sm:p-6 bg-white80 -mx-4 sm:mx-0 rounded">
      {lookupKeys?.map(key => (
        <React.Fragment key={key}>
          {!ignoreKeys.includes(key?.toLowerCase()) && (
            <div
              className={`py-4 px-4 sm:px-6 flex items-center justify-between ${
                key === 'affiliates' && showAffiliateDetails
                  ? ''
                  : 'border-b border-grey60'
              } `}
            >
              <p className="text-xs text-grey font-medium uppercase text-left">
                {key === 'affiliates' ? 'Number of Affiliates' : checkKey(key)}
              </p>

              <div className="text-small text-body w-100 text-right">
                {key === 'affiliates' ? (
                  <div className="flex items-center gap-1">
                    <button
                      type="button"
                      className="text-sm font-medium text-brandBlue cursor-pointer -mb-1"
                      onClick={() => setShowAffiliateDetails(prev => !prev)}
                    >
                      {showAffiliateDetails ? (
                        <>
                          <span className="text-body">
                            {lookupData[key]?.length}{' '}
                          </span>
                          <span>minimize details</span>
                        </>
                      ) : (
                        'Expand details'
                      )}
                    </button>
                    <img
                      src={chevronRight}
                      alt="right arrow"
                      width={16}
                      height={16}
                    />
                  </div>
                ) : (
                  <span>
                    {(lookupKeys &&
                    typeof lookupData[key] !== 'object' &&
                    ['createdAt', 'updatedAt'].includes(key)
                      ? moment(lookupData[key]).format('DD MMM YYYY h:m')
                      : lookupData[key]) || '-'}
                  </span>
                )}
              </div>
            </div>
          )}
          {key === 'affiliates' && showAffiliateDetails && (
            <div className="flex flex-col gap-2 w-full px-6">
              {lookupData[key]?.map((affiliate, index) => (
                <div
                  key={index}
                  className="bg-[#F3F3F3] py-4 px-2.5 flex flex-col items-start gap-4 rounded-lg"
                >
                  <p className="uppercase text-grey text-xs font-medium">{`Affiliate ${
                    index + 1
                  }`}</p>

                  <div className="flex flex-col gap-2 w-full">
                    {affiliateskeysOfInterest?.map(detailKey => (
                      <div
                        key={detailKey}
                        className="flex items-center justify-between gap-6 border-b border-grey60 w-full py-2"
                      >
                        <p className="uppercase text-xs font-medium text-[#5E5E5E]">
                          {detailKey === 'name'
                            ? 'Full name'
                            : formatKey(detailKey)}
                        </p>
                        <p className="text-sm font-medium text-grey text-end">
                          {affiliate[detailKey] || '-'}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  </div>
);
