import React, {useEffect, useMemo} from 'react';
import Select from 'react-select';
import {connect} from 'react-redux';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import * as Actions from '../../store/actions';
import {customStyles, DropdownIndicator} from '../ReactSelect';
import {hiddenTypes, numberInputs} from '../../routes/EasyLookup/LookupUtils';
import {Constants} from '../../helpers';
import countryList from 'react-select-country-list';

function Nigeria({getBankCodes, lookup: {banks, loading}, ...otherProps}) {
  const {lookupInputs, setLookupInputs, batch, businessData} = otherProps;

  const allBanks = useMemo(
    () =>
      (!loading &&
        banks &&
        banks?.map(bank => ({
          value: bank.code,
          label: bank.name,
          name: bank.name,
        }))) ||
      [],
    [banks, loading],
  );

  useEffect(() => {
    if (['account_number', 'nuban'].includes(lookupInputs?.type?.value)) {
      getBankCodes();
    }
  }, [lookupInputs.type, getBankCodes]);

  const countries = useMemo(() => countryList().getData(), []);

  const Option = ({data, isSelected, isFocused, innerRef, innerProps}) => (
    <div
      ref={innerRef}
      {...innerProps}
      className={`react-select__option ${
        isSelected ? 'react-select__option--is-selected' : ''
      } ${isFocused ? 'react-select__option--is-focused' : ''}`}
      aria-disabled="false"
      tabIndex="-1"
    >
      <span className="mr-2">{getUnicodeFlagIcon(data.value)}</span>
      <span>{data.label}</span>
    </div>
  );

  const companyTypeOptions = [
    {value: 'BUSINESS_NAME', label: 'Business Name'},
    {value: 'COMPANY', label: 'Company'},
    {value: 'INCORPORATED_TRUSTESS', label: 'Incorporated Trustess'},
    {value: 'LIMITED_PARTNERSHIP', label: 'Limited Partnership'},
    {
      value: 'LIMITED_LIABILITY_PARTNERSHIP',
      label: 'Limited Liability Partnership',
    },
  ];

  return (
    <>
      {!batch && (
        <div
          className={`globalsearch ${
            lookupInputs.type === null ? 'hidden' : 'block'
          } mt-8 sm:bg-white80 sm:p-6 rounded`}
        >
          {lookupInputs?.type?.value === 'cac_advance' && (
            <label htmlFor="company_type" className="relative mb-6 block">
              <p className="mb-2">Company Type</p>
              <Select
                id="company_type"
                name="company_type"
                className="mt-2 placeholder:italic"
                placeholder="Select Company Type"
                value={lookupInputs?.company_type}
                onChange={company_type =>
                  setLookupInputs({
                    ...lookupInputs,
                    company_type,
                  })
                }
                options={companyTypeOptions}
                components={{DropdownIndicator}}
                classNamePrefix="react-select"
                styles={customStyles}
              />
            </label>
          )}
          {lookupInputs?.type?.value === 'global_search' ? (
            <label htmlFor="country" className="relative mb-6 block">
              <p className="mb-2">Select Country</p>
              <Select
                defaultValue={lookupInputs?.country_code}
                value={lookupInputs?.country_code}
                onChange={country_code =>
                  setLookupInputs({...lookupInputs, country_code})
                }
                options={countries}
                placeholder="Select Country"
                components={{DropdownIndicator, Option}}
                classNamePrefix="react-select"
                styles={customStyles}
              />
            </label>
          ) : (
            <label htmlFor="idType" className="relative">
              {lookupInputs?.type?.value?.includes('cac')
                ? 'RC number'
                : lookupInputs?.type?.label}
              <input
                {...(businessData?.id_number
                  ? {value: businessData?.id_number, disabled: true}
                  : {})}
                id="idType"
                className={`mt-2 placeholder:italic ${
                  businessData?.id_number ? 'cursor-not-allowed' : ''
                }`}
                placeholder={`Enter ${
                  lookupInputs?.type?.value?.includes('cac')
                    ? 'RC number'
                    : lookupInputs?.type?.label || 'Value'
                } here...`}
                type={
                  numberInputs.includes(lookupInputs?.type?.value)
                    ? 'number'
                    : 'text'
                }
                onChange={e =>
                  setLookupInputs({
                    ...lookupInputs,
                    lookupValue: String(e.target.value),
                  })
                }
              />
              {lookupInputs?.type?.value === 'vnin' && (
                <div className="flex justify-center w-full items-center pt-3">
                  <p className="text-sm text-grey20">
                    Dial
                    <span className="text-red mb-2">
                      {' '}
                      *346*3*NIN*1138183#
                    </span>{' '}
                    to generate your vNIN, <br />
                    <cite className="mt-8">
                      Note, you can only use the vNIN generated once.
                    </cite>
                  </p>
                </div>
              )}
            </label>
          )}
          {lookupInputs?.type?.value === 'cac' ? (
            <label htmlFor="company_type" className="relative mt-6 block">
              Company Type
              <Select
                id="company_type"
                name="company_type"
                className="mt-2 placeholder:italic"
                placeholder="Select Company Type"
                value={lookupInputs?.company_type}
                onChange={company_type =>
                  setLookupInputs({
                    ...lookupInputs,
                    company_type,
                  })
                }
                options={companyTypeOptions}
                components={{DropdownIndicator}}
                classNamePrefix="react-select"
                styles={customStyles}
              />
            </label>
          ) : (
            lookupInputs?.type?.value === 'global_search' && (
              <label htmlFor="company_name" className="relative mt-5">
                Company Name
                <input
                  id="company_name"
                  name="company_name"
                  className="mt-2 placeholder:italic"
                  placeholder="Dojah"
                  type="text"
                  onChange={e =>
                    setLookupInputs({
                      ...lookupInputs,
                      company_name: e.target.value,
                    })
                  }
                />
              </label>
            )
          )}
          {lookupInputs?.type?.value === 'passport' && (
            <label htmlFor="surname" className="relative mt-5">
              Surname
              <input
                id="surname"
                name="surname"
                className="mt-2 placeholder:italic"
                placeholder="Surname"
                type="text"
                onChange={e =>
                  setLookupInputs({
                    ...lookupInputs,
                    surname: e.target.value,
                  })
                }
              />
            </label>
          )}
          {(lookupInputs?.type?.value === 'vin' ||
            lookupInputs?.type?.value === 'voters_id') && (
            <>
              <label htmlFor="first_name" className="relative mt-5">
                First Name
                <input
                  id="first_name"
                  name="first_name"
                  className="mt-2 placeholder:italic"
                  placeholder="John"
                  type="text"
                  onChange={e =>
                    setLookupInputs({
                      ...lookupInputs,
                      firstname: e.target.value,
                    })
                  }
                />
              </label>

              <label htmlFor="last_name" className="relative mt-5">
                Last Name
                <input
                  id="last_name"
                  name="last_name"
                  className="mt-2 placeholder:italic"
                  placeholder="Doe"
                  type="text"
                  onChange={e =>
                    setLookupInputs({
                      ...lookupInputs,
                      lastname: e.target.value,
                    })
                  }
                />
              </label>
              <label htmlFor="state" className="relative mt-5">
                State
                <input
                  id="state"
                  name="state"
                  className="mt-2 placeholder:italic"
                  placeholder="Delta"
                  type="text"
                  onChange={e =>
                    setLookupInputs({
                      ...lookupInputs,
                      state: e.target.value,
                    })
                  }
                />
              </label>
            </>
          )}
          <label
            htmlFor="bank"
            className={`${
              lookupInputs?.type?.value === 'nuban' ||
              lookupInputs?.type?.value === 'account_number'
                ? 'block'
                : 'hidden'
            } relative mt-6`}
          >
            Bank Name
            <Select
              defaultValue={lookupInputs?.bank_code}
              value={lookupInputs?.bank_code}
              onChange={bank_code =>
                setLookupInputs({...lookupInputs, bank_code})
              }
              options={allBanks}
              placeholder="Select your bank"
              components={{DropdownIndicator}}
              classNamePrefix="react-select"
              styles={customStyles}
              isSearchable={true}
            />
          </label>
          {/* custom datepicker   */}
          {/* <DatePicker
          selected={startDate}
          onChange={date => setStartDate(date)}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select".
        /> */}
          <label
            htmlFor="date"
            className={`${
              hiddenTypes.includes(lookupInputs?.type?.value) ||
              lookupInputs?.type?.name === 'ao_id'
                ? 'hidden'
                : 'block'
            } relative mt-6`}
          >
            Date of birth
            <input
              id="date"
              type="date"
              className="mt-2 placeholder:italic"
              onChange={e =>
                setLookupInputs({
                  ...lookupInputs,
                  dob: e.target.value,
                })
              }
            />
          </label>
        </div>
      )}
    </>
  );
}

export default connect(state => state, Actions)(Nigeria);
