import {isObjectEmpty} from '../../helpers/isObjectEmpty';
import pdfMake from 'pdfmake/build/pdfmake';
import {pushObjectsIntoArray} from '../../helpers/pushOnlyFourItemsArrayOfArrays';
import {
  generateFooter,
  generatePDFStructure,
  horizontalLine,
  styles,
} from '../../helpers/pdfUtils';

// Create a minimal virtual file system with an empty default font
const vfs = {
  Roboto: {
    normal: '',
    bold: '',
    italics: '',
    bolditalics: '',
  },
};

// Initialize pdfMake with the minimal VFS
pdfMake.vfs = vfs;

// Define fonts explicitly without any actual font files
pdfMake.fonts = {
  Roboto: {
    normal:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.2.7/fonts/Roboto/Roboto-Regular.ttf',
    bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.2.7/fonts/Roboto/Roboto-Medium.ttf',
    italics:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.2.7/fonts/Roboto/Roboto-Italic.ttf',
    bolditalics:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.2.7/fonts/Roboto/Roboto-MediumItalic.ttf',
  },
};

const generateHeader = ({lookupType, lookupImage, companyName}) => {
  const imageData =
    lookupImage && lookupImage?.length >= 1000
      ? [
          {
            image: lookupImage,
            width: 150,
            alignment: 'left',
            margin: [0, 0, 0, 20],
          },
        ]
      : [];
  return [
    {text: lookupType || 'Easy Lookup', style: 'type'},
    {
      text: `Requested by ${companyName}`,
      style: {alignment: 'center', fontSize: 11, color: '#4A4A68'},
      margin: [0, 0, 0, 30],
    },
    {
      text: 'Profile'.toUpperCase(),
      margin: [0, 10, 0, 7],
      style: {bold: true, fontSize: 10},
    },
    horizontalLine(),
    ...imageData,
  ];
};

const generatePDFStructureForEasyLookup = ({
  lookupData,
  logo,
  lookupType,
  lookupImage,
  companyName,
}) => {
  const generateDocInfo = lookupData
    .filter(doc => !isObjectEmpty(doc))
    .map(doc => doc);

  const headerRow = generateHeader({
    logo,
    lookupType,
    lookupImage,
    companyName,
  });
  const content = [...headerRow];
  const finalContentStructure = generatePDFStructure(generateDocInfo);
  const dataColumns = pushObjectsIntoArray(finalContentStructure);
  content.push(dataColumns);
  return content;
};

const generatePDF = (contents, footerData, lookupType) => {
  const documentDefinition = {
    // watermark,
    content: [...contents],
    styles,
    footer: generateFooter(footerData),
  };

  try {
    const defaultDocDefinition = {
      ...documentDefinition,
      defaultStyle: {
        ...documentDefinition.defaultStyle,
        font: 'Roboto',
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(defaultDocDefinition);
    pdfDocGenerator.download(`${lookupType}.pdf`.toLowerCase());
  } catch (error) {
    console.error('PDF Generation Error:', {
      message: error.message,
      stack: error.stack,
      docDefinition: JSON.stringify(documentDefinition, null, 2),
    });

    // Log specific error types
    if (error.message.includes('not found in virtual file system')) {
      console.error('Image loading error - Check image paths and availability');
    }

    // Handle the error appropriately based on your application needs
    throw new Error(`PDF generation failed: ${error.message}`);
  }
};

export {generatePDFStructureForEasyLookup, generatePDF};
