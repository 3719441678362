import React from 'react';
import {connect} from 'react-redux';
import * as Actions from '../../store/actions';

function Uganda({lookupInputs, setLookupInputs}) {
  return (
    <>
      <div
        className={`${
          lookupInputs.type === null ? 'hidden' : 'block'
        } mt-8 sm:bg-white80 sm:p-6 rounded`}
      >
        <label htmlFor="idType" className="relative">
          {lookupInputs?.type?.label}
          <input
            id="idType"
            className="mt-2"
            placeholder={`Enter ${lookupInputs?.type?.label} here`}
            type="text"
            onChange={e =>
              setLookupInputs({
                ...lookupInputs,
                lookupValue: e.target.value,
              })
            }
          />
        </label>
        {lookupInputs?.type?.value !== 'nin' && (
          <>
            <label htmlFor="firstname" className="relative mt-5">
              First Name
              <input
                id="firstname"
                name="firstname"
                className="mt-2"
                placeholder="John"
                type="text"
                onChange={e =>
                  setLookupInputs({
                    ...lookupInputs,
                    firstname: e.target.value,
                  })
                }
              />
            </label>

            <label htmlFor="lastname" className="relative mt-5">
              Last Name
              <input
                id="lastname"
                name="lastname"
                className="mt-2"
                placeholder="Doe"
                type="text"
                onChange={e =>
                  setLookupInputs({
                    ...lookupInputs,
                    lastname: e.target.value,
                  })
                }
              />
            </label>
          </>
        )}
      </div>
    </>
  );
}

export default connect(state => state, Actions)(Uganda);
