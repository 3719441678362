/* eslint-disable react-hooks/exhaustive-deps */
import React, {useMemo} from 'react';
import {connect} from 'react-redux';
import Select from 'react-select';

const BusinessData = ({widgets: {widgetPages, widgetScreen}}) => {
  const widgetData =
    widgetPages &&
    widgetPages?.find(
      widget =>
        widget?.page === 'business-data' &&
        widget?.pageId === widgetScreen?.pageId,
    );

  const data = useMemo(
    () => ({
      ...widgetData?.config,
    }),
    [widgetData?.config?.cac, widgetData?.config?.tin],
  );

  const companyTypeOptions = [
    {value: 'BUSINESS_NAME', label: 'Business Name'},
    {value: 'COMPANY', label: 'Company'},
    {value: 'INCORPORATED_TRUSTESS', label: 'Incorporated Trustess'},
    {value: 'LIMITED_PARTNERSHIP', label: 'Limited Partnership'},
    {
      value: 'LIMITED_LIABILITY_PARTNERSHIP',
      label: 'Limited Liability Partnership',
    },
  ];

  return (
    <>
      <header className="flex flex-col items-center mt-2">
        <h4 className="mt-4 font-medium text-grey">Business Data</h4>
      </header>
      <form className="w-full max-w-sm mt-10 mb-5">
        <label htmlFor="rc_number" className="mb-1">
          RC Number
          <input
            type="text"
            id="rc_number"
            value="12345"
            readOnly
            className="mt-2 text-sm"
            placeholder="Enter RC Number"
          />
        </label>
        <label htmlFor="company_type" className="mt-10 mb-1 block">
          Company Type
          <Select
            id="company_type"
            className="mt-2"
            options={companyTypeOptions}
            value={companyTypeOptions.find(
              option => option.value === data?.type,
            )}
            onChange={selectedOption => {
              // onChange
            }}
            placeholder="Select Company Type"
            classNamePrefix="react-select"
          />
        </label>
      </form>
    </>
  );
};

export default connect(state => state, null)(BusinessData);
