const buildQueryParams = ({
  app,
  country,
  type,
  bank_code,
  marital_status,
  gender,
  cac_type,
  country_code,
  dob,
  state,
  firstname,
  lastname,
  full_name,
  surname,
  ghanaDlDob,
  date_of_birth,
  mode,
  mobile,
  company_name,
  company,
  company_type,
}) => {
  const isGhana = country?.value === 'ghana';
  const isUganda = country?.value === 'uganda';
  const isZimbabwe = country?.value === 'zimbabwe';
  const isUgandaNin = isUganda && type?.value !== 'nin';
  const isZimbabweFcb = isZimbabwe && type?.value === 'fcb';

  const queryParams = {};

  if (app?.value) queryParams.app_id = app.value;
  if (dob) queryParams.dob = dob;
  if (state) queryParams.state = state;

  if (!isGhana && (isUgandaNin || isZimbabweFcb) && firstname) {
    queryParams.full_name = firstname;
  }

  if ((isGhana || isUgandaNin || isZimbabweFcb) && firstname) {
    queryParams.first_name = firstname;
  }

  if (!isGhana && (isUgandaNin || isZimbabweFcb) && lastname) {
    queryParams.last_name = lastname;
  }

  if (isGhana && full_name && date_of_birth) {
    queryParams.full_name = full_name;
    queryParams.date_of_birth = date_of_birth;
  }

  if (surname) queryParams.surname = surname;

  if (type?.value === 'gh-dl' && ghanaDlDob) {
    queryParams.gh_dl = ghanaDlDob;
  }

  if ((isGhana && type?.value === 'passport') || isZimbabweFcb) {
    queryParams.date_of_birth = dob || date_of_birth;
  }

  if (bank_code?.value) queryParams.bank_code = bank_code.value;
  if (mode) queryParams.mode = mode;
  if (mobile) queryParams.mobile = mobile;
  if (marital_status?.value) queryParams.marital_status = marital_status.value;
  if (gender?.value) queryParams.gender = gender.value;
  if (cac_type?.value) queryParams.type = cac_type.value;
  if (company_type?.value) queryParams.company_type = company_type.value;

  if (company_name) {
    queryParams.company_name = company_name.replace(/%/g, '%25');
  }

  if (company) {
    queryParams.company = company.replace(/%/g, '%25');
  }

  if (country_code?.value) {
    queryParams.country_code = country_code.value.toLowerCase();
  }

  // Converting the query parameters object to a string
  return Object.entries(queryParams || {})
    .filter(([, value]) => value !== undefined)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');
};

export {buildQueryParams};
