import React from 'react';
import moment from 'moment';
import {PrimaryButton} from '../../../../components';

export const LookupFooter = ({lookupData, downloadPDF, content}) => (
  <footer
    className="rounded-b-xl flex flex-col  py-2 sm:py-6 px-4 sm:px-0 bg-white80 w-full sticky bottom-0 print:hidden"
    style={{boxShadow: '0px -1px 8px rgba(51, 52, 66, 0.25)'}}
  >
    <div className="print:flex print:items-center print:justify-between text-xs text-body uppercase mt-2 mb-6 px-[5.88%]">
      <span>
        Performed by:{' '}
        {lookupData?.caller_name || lookupData?.caller_email
          ? lookupData?.caller_name || lookupData?.caller_email
          : '-'}
      </span>
      <span className="ml-8">
        Generated:{' '}
        {lookupData?.date_created
          ? moment(lookupData?.date_created).format('MMM DD, YYYY hh:mmA')
          : '-'}
      </span>
    </div>
    <PrimaryButton
      buttonText="Download as PDF"
      className="w-full sm:max-w-sm self-center"
      onClick={downloadPDF}
      disabled={!content}
    />
  </footer>
);
