import React from 'react';
import {back, closeModal} from '../../../../assets/images/images';

export const ModalHeader = ({
  logo,
  lookupType,
  setOpen,
  changeLookupPage,
  clearLookupDetails,
}) => (
  <>
    <div className="hidden print:flex print:items-center print:justify-between">
      <img src={logo} alt="" style={{maxHeight: '40px'}} />
      <h3 className="text-grey text-base font-medium">
        {lookupType?.label} verification
      </h3>
    </div>
    <button
      onClick={() => {
        setOpen(false);
        changeLookupPage(1);
      }}
      className="flex items-center gap-2 sm:hidden bg-white80 p-2 pl-0 print:hidden"
    >
      <img src={back} alt="" />
      <span className="text-small text-grey80 font-medium">
        Back to Easy Lookup
      </span>
    </button>
    <header className="mt-6 sm:mt-0 flex items-center justify-center sm:justify-between print:hidden">
      <div className="flex items-center gap-2 flex-col sm:flex-row">
        <h3 className="text-grey text-base font-medium">
          {lookupType?.label} verification
        </h3>
        <div className="flex items-center gap-2">
          <div className="uppercase py-1 px-2 text-xs text-success font-medium bg-success20 rounded-full w-fit">
            individual
          </div>
          <div className="uppercase py-1 px-2 text-xs text-success20 font-medium bg-success rounded-full w-fit">
            completed
          </div>
        </div>
      </div>
      <button
        onClick={() => {
          setOpen(false);
          setTimeout(() => {
            changeLookupPage(1);
            clearLookupDetails();
          }, 1000);
        }}
        className="hidden sm:block"
      >
        <div>
          <img src={closeModal} alt="" />
        </div>
      </button>
    </header>
  </>
);
