import React, {useContext, useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {LogoContext, PageContext} from '../../../helpers/context';
import {Constants} from '../../../helpers';
import * as Actions from '../../../store/actions';
import {Loader} from '../../../components';
import {SANDBOX_DUMMY} from '../SandBoxDummyLookup';
import {
  generatePDF,
  generatePDFStructureForEasyLookup,
} from '../generatePDFStructureForEasyLookup';
import {
  convertSVGToBase64,
  imageUrlToBase64,
} from '../../../helpers/convertSVGToBase64';
import {affiliateskeysOfInterest, lookupNames} from '../LookupUtils';
import {sandbox_dummy} from '../../../assets/images/images';
import {getCookieValue} from '../../../helpers/getCookie';
import {CompanySearchModal} from './components/CompanySearchModal';
import {ModalHeader} from './components/ModalHeader';
import {LookupContent} from './components/LookupContent';
import {LookupFooter} from './components/LookupFooter';
import {checkKey} from '../../../helpers/constants/ckeckLookupKeys';
import {clearLookupDetails} from '../../../store/actions';

const formatKey = key =>
  key
    .split(/(?=[A-Z])|_/)
    .map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
    .join(' ');

const EasyLookupDetails = ({
  changeLookupPage,
  lookup: {lookup, loading},
  open,
  setOpen,
  auth: {userDetails, user},
}) => {
  const logo = useContext(LogoContext);
  const {setPageName} = useContext(PageContext);
  const [svgBase64, setSvgBase64] = useState('');
  const [lookupImageBase64, setLookupImageBase64] = useState('');
  const [showAffiliateDetails, setShowAffiliateDetails] = useState(false);

  useEffect(() => {
    setPageName('lookup');
    convertSVGToBase64().then(setSvgBase64);
  }, [setPageName]);

  const env = getCookieValue('env');
  const allCountryIds = useMemo(
    () => [
      ...Constants.lookups.nigeriaId,
      ...Constants.lookups.ghanaId,
      ...Constants.lookups.kenyaId,
      ...Constants.lookups.ugandaId,
    ],
    [],
  );

  const lookupType = useMemo(
    () =>
      allCountryIds.find(id => {
        const lookupId =
          id?.value?.toLowerCase() === 'basic'
            ? 'basic_phone'
            : id?.value?.toLowerCase() === 'nin'
            ? 'ng_nin'
            : id?.value?.toLowerCase();
        return lookupId === lookup?.type;
      }),
    [allCountryIds, lookup?.type],
  );

  const lookupData = useMemo(() => {
    const data =
      env !== 'Sandbox'
        ? (lookup && lookup[lookup?.type]) || {}
        : SANDBOX_DUMMY;
    return Object.fromEntries(
      Object.entries(data).filter(
        ([_, value]) => value !== '' && value !== null && value !== ' ',
      ),
    );
  }, [env, lookup]);

  const lookupKeys = Object.keys(lookupData);

  const data = useMemo(() => {
    if (loading) return [];
    return lookupKeys?.flatMap(key => {
      if (key === 'affiliates') {
        return lookupData[key]?.map((affiliate, index) => ({
          [`Affiliate ${index + 1}`]: affiliateskeysOfInterest
            .map(affiliateKey => {
              const formattedKey = formatKey(affiliateKey);
              return `${formattedKey}: ${affiliate[affiliateKey] || '-'}`;
            })
            .join('\n'),
        }));
      }
      return {[checkKey(key)]: lookupData[key]};
    });
  }, [loading, lookupKeys, lookupData]);

  const lookupImage =
    lookupData?.photo && !['Not Available'].includes(lookupData.photo)
      ? lookupData.photo
      : lookupData?.image || lookupData?.picture || lookupData?.image_url || '';

  useEffect(() => {
    if (lookupImage?.startsWith('http')) {
      imageUrlToBase64(lookupImage).then(setLookupImageBase64);
    }
  }, [lookupImage]);

  const content = useMemo(() => {
    if (loading || data.length === 0) return null;
    return generatePDFStructureForEasyLookup({
      lookupData: data,
      logo: svgBase64,
      ...(lookupImage
        ? {
            lookupImage: lookupImage?.startsWith('http')
              ? lookupImageBase64
              : `data:image/png;base64,${lookupImage}`,
          }
        : {}),
      lookupType: lookupType?.label || lookup?.type,
      companyName: userDetails?.company?.name,
    });
  }, [
    loading,
    data,
    svgBase64,
    lookupImage,
    lookupImageBase64,
    lookupType?.label,
    lookup?.type,
    userDetails?.company?.name,
  ]);

  const pdfFooter = {
    name:
      lookupData?.caller_name ||
      lookupData?.caller_email ||
      `${user?.first_name} ${user?.last_name}`,
    generated: lookupData?.date_created,
    logo: svgBase64,
  };

  const downloadPDF = () => {
    setOpen(false);
    generatePDF(content, pdfFooter, lookupType?.label || lookup?.type);
  };

  const companyNames = ['company_name', 'Name_of_Company'];
  const firstNames = lookupNames.firstNames.filter(n => lookupData[n]);
  const lastNames = lookupNames.lastNames.filter(n => lookupData[n]);

  if (lookup && lookup?.type === 'company_search') {
    return (
      <CompanySearchModal
        open={open}
        setOpen={setOpen}
        changeLookupPage={changeLookupPage}
        loading={loading}
        lookup={lookup}
      />
    );
  }

  return (
    <>
      <div className="p-4 sm:p-10 sm:pb-6">
        <ModalHeader
          logo={logo}
          lookupType={lookupType}
          setOpen={setOpen}
          changeLookupPage={changeLookupPage}
          clearLookupDetails={clearLookupDetails}
        />

        {loading ? (
          <Loader height={96} className="mt-8" />
        ) : (
          <LookupContent
            env={env}
            lookupImage={lookupImage}
            sandbox_dummy={sandbox_dummy}
            lastNames={lastNames}
            firstNames={firstNames}
            companyNames={companyNames}
            lookupData={lookupData}
            lookupKeys={lookupKeys}
            showAffiliateDetails={showAffiliateDetails}
            setShowAffiliateDetails={setShowAffiliateDetails}
            formatKey={formatKey}
          />
        )}
      </div>

      {!loading && lookup && (
        <LookupFooter
          lookupData={lookupData}
          downloadPDF={downloadPDF}
          content={content}
        />
      )}
    </>
  );
};

export default connect(state => state, Actions)(EasyLookupDetails);
