import {savePDF} from '@progress/kendo-react-pdf';
import React, {useEffect, useRef, useState} from 'react';
import {dojah, downloadGrey, signatureLogo} from 'assets/images/images';
import moment from 'moment';
import {useSelector} from 'react-redux';
import {imageUrlToBase64} from '../../../helpers/convertSVGToBase64';

const GenerateAndDownloadSignaturePDF = ({logo, text, name, signature}) => {
  const [logoToBase64, setLogoToBase64] = useState('');
  const {userDetails} = useSelector(state => state.auth);

  const pdfOptions = {
    pageSize: 'A4',
    paperSize: 'A4',
    multiPage: true,
    imageResolution: 300,
    margin: '40pt',
  };

  const signaturePdf = useRef(null);

  useEffect(() => {
    if (logo?.startsWith('http')) {
      imageUrlToBase64(logo).then(setLogoToBase64);
    }
  }, [logo]);

  const download = () => {
    savePDF(signaturePdf.current, pdfOptions);
  };

  return (
    <>
      <button
        className="h-7 bg-white80 rounded flex items-center justify-center gap-2 p-2 ml-auto"
        onClick={() => download()}
      >
        <span className="text-xs font-medium">Download</span>
        <img src={downloadGrey} alt="" className="-mt-[2px]" />
      </button>
      <div className="w-full h-[0px] overflow-hidden">
        <div
          className="h-full flex flex-col justify-between"
          ref={signaturePdf}
        >
          <div className="w-full">
            <div className="w-full flex justify-between">
              <img
                className="signature-logo h-[40px]"
                src={logoToBase64 || signatureLogo}
                alt="Dojah Logo"
              />
              <div className="italic text-xs">
                <p>{`Generated by ${userDetails?.company?.name || 'Dojah'}`}</p>
                <p className="text-[#727272]">
                  {moment().format('D MMM, YYYY h:mm a')}
                </p>
              </div>
            </div>
            <p className="mt-8 mb-4 text-[0.9rem]">
              Signature and confirmation document
            </p>
            <p className="text-[#777] font-light text-[0.9rem]">{text}</p>
            <div className="w-full text-[0.8rem] flex justify-between mt-8">
              <div className="flex items-end">
                <p>Full Name: </p>
                <p className="ml-2 max-h-fit border-b border-[#000000]">
                  {name}
                </p>
              </div>
              <div className="flex items-end">
                <p>Signature: </p>
                <div className="ml-2 border-b border-[#000000]">
                  <img
                    className="signature-logo max-w-[120px]"
                    src={`data:image/png;base64, ${signature}`}
                    alt="Dojah Logo"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex text-sm items-center justify-center">
            <p className="mr-1 mb-1">Powered by</p>
            <img
              className="signature-logo h-[17px]"
              src={dojah}
              alt="Dojah Logo"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default GenerateAndDownloadSignaturePDF;
