import React, {createContext, useEffect, useState} from 'react';
import {api} from '../index';

const PageContext = createContext(null);

function PageProvider({children}) {
  const [pageName, setPageName] = useState('');

  useEffect(() => {
    if (pageName !== '') {
      const data = {
        section: pageName,
      };
      api
        .post('/users/view', {...data})
        .then(res => res.data)
        .catch(e => {
          console.log(e);
        });
    }
  }, [pageName]);

  return (
    <PageContext.Provider value={{setPageName}}>
      {children}
    </PageContext.Provider>
  );
}

export {PageProvider, PageContext};
