/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState, useCallback, useContext} from 'react';
import {
  PrimaryButton,
  SecondaryButton,
  PhoneNumberInput,
} from '../../../components';
import {lock, profileIc} from '../../../assets/images/images';
import ChangePasswordModal from './ChangePasswordModal';
import SettingsLayout from '../SettingsLayout';
import {connect} from 'react-redux';
import * as Actions from '../../../store/actions';
import SetPasswordModal from './SetPasswordModal';
import {PageContext} from '../../../helpers/context';
import {AMLToggle} from '../../../components/Toggle';
import {ScanQRCodeModal} from './2FA-Modals/ScanQRCodeModal';
import {ValidateOTPModal} from './2FA-Modals/ValidateOTPModal';
import {Successful2FA} from './2FA-Modals/Successful2FA';
import {useMutation} from 'react-query';
import {setUp2FA} from '../../../requests/queries/settings';

function SettingsProfile({auth: {user, loading}, updateUserProfile}) {
  const [phone, setPhone] = useState('');
  const [_2FAData, set2FAData] = useState();

  const [openChangePasswordModal, setOpenCPM] = useState(false);
  const [openSetPasswordModal, setOpenSPM] = useState(false);
  const [qrCode, setQRCodeOpen] = useState(false);
  const [validateOtp, setValidateOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [inputs, setUserInputs] = useState({
    first_name: user?.first_name || '',
    last_name: user?.last_name || '',
  });

  const {mutate: setUp2FAMutation, isLoading} = useMutation(setUp2FA, {
    onSuccess: data => {
      set2FAData(data);
    },
  });

  const handleChange = e => {
    setUserInputs({...inputs, [e.target.name]: e.target.value});
  };

  useEffect(() => {
    if (inputs?.first_name || inputs?.last_name) {
      setUserInputs(prev => ({
        ...user,
        ...prev,
        mobile: phone,
      }));
    }
    setPhone(user?.mobile);
  }, [user, inputs?.first_name, inputs?.last_name]);

  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('profile');
  }, [setPageName]);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      if (inputs) {
        updateUserProfile({
          first_name: inputs.first_name,
          last_name: inputs.last_name,
          mobile: phone,
        });
      }
    },
    [phone, updateUserProfile, inputs],
  );

  const handle2FASetup = () => {
    if (!user?.two_factor_auth) {
      setUp2FAMutation();
      !isLoading && setQRCodeOpen(!qrCode);
    } else {
      setValidateOpen(!validateOtp);
    }
  };

  return (
    <SettingsLayout
      pageTitle="Profile"
      pageIcon={profileIc}
      action={
        <PrimaryButton
          onClick={handleSubmit}
          buttonText="Save Changes"
          xPadding="px-4"
          className="hidden sm:flex"
          loading={loading}
          disabled={loading}
        />
      }
    >
      <ChangePasswordModal
        open={openChangePasswordModal}
        setOpen={setOpenCPM}
      />

      <ScanQRCodeModal
        open={qrCode}
        setOpen={() => setQRCodeOpen(!qrCode)}
        setOTPOpen={() => {
          setValidateOpen(!validateOtp);
          setQRCodeOpen(!qrCode);
        }}
        isLoading={isLoading}
        _2FAData={_2FAData}
      />
      <ValidateOTPModal
        open={validateOtp}
        setOpen={() => setValidateOpen(!validateOtp)}
        onComplete={() => {
          setSuccessOpen(!successOpen);
          setValidateOpen(!validateOtp);
        }}
        isEnabled={user?.two_factor_auth}
      />

      <Successful2FA setOpen={setSuccessOpen} open={successOpen} />
      <SetPasswordModal open={openSetPasswordModal} setOpen={setOpenSPM} />

      <section className="mt-8 md:mt-10">
        <form className="flex flex-col w-full gap-6 md:flex-row md:items-start">
          <div className="md:w-1/2">
            <h3 className="text-xs font-medium uppercase text-body">
              personal information
            </h3>

            <div className="p-4 mt-4 bg-white rounded md:mt-6 md:p-6">
              <label htmlFor="first_name">
                First name
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  className="mt-2"
                  placeholder={inputs?.first_name || 'John'}
                  onChange={handleChange}
                  value={inputs?.first_name || ''}
                />
              </label>
              <label htmlFor="last_name" className="mt-6">
                Last name
                <input
                  type="text"
                  id="last_name"
                  name="last_name"
                  className="mt-2"
                  placeholder={inputs?.last_name || 'Doe'}
                  onChange={handleChange}
                  value={inputs?.last_name || ''}
                />
              </label>
              <label htmlFor="foom" className="mt-6 mb-6">
                Email address
                <input
                  type="email"
                  name="email"
                  id="foom"
                  className="mt-2 cursor-not-allowed"
                  placeholder={inputs?.email || 'me@example.com'}
                  disabled
                  value={inputs?.email || ''}
                />
              </label>
              <PhoneNumberInput phone={phone} setPhone={setPhone} />
            </div>
          </div>
          <div className="rounded md:w-1/2">
            <>
              <h3 className="text-xs font-medium uppercase text-body">
                security
              </h3>

              <div className="p-6 mt-3 bg-white">
                <SecondaryButton
                  buttonText={
                    user?.hasPassword ? 'Change password' : 'Set password'
                  }
                  className="flex gap-2 py-2 bg-white align-start text-grey80"
                  xPadding="px-3"
                  src={lock}
                  alt="lock"
                  width={16}
                  height={16}
                  onClick={
                    user?.hasPassword
                      ? () => setOpenCPM(true)
                      : () => setOpenSPM(true)
                  }
                  disabled={loading}
                />
              </div>
            </>

            <div className="mt-5">
              <h3 className="text-xs font-medium uppercase text-body">
                2 factor authentication (2fa)
              </h3>

              <div className="p-6 mt-3 bg-white">
                <h3 className="text-sm font-medium capitalize text-body mb-3">
                  2FA Authorisation
                </h3>

                <div className="flex items-center justify-between w-[87%]">
                  <p className="text-grey-500 text-sm">
                    This will {user?.two_factor_auth ? 'disable' : 'enable'}{' '}
                    2-Factor authorisation for you only
                  </p>
                  <div className="flex items-center gap-3 relative">
                    <AMLToggle
                      isChecked={user?.two_factor_auth}
                      title="This will enable 2-Factor authorisation for you only"
                      onCheck={handle2FASetup}
                    />
                    {(isLoading || loading) && (
                      <div className="absolute flex items-center -bottom-2 right-8 mb-2">
                        <span className="text-xs mr-3 italic  text-batchBlue">
                          Loading...
                        </span>
                        <div className="spinner "></div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <PrimaryButton
            buttonText="Save Changes"
            xPadding="block sm:hidden w-full"
            onClick={handleSubmit}
            loading={loading}
          />
        </form>
      </section>
    </SettingsLayout>
  );
}

export default connect(state => state, Actions)(SettingsProfile);
