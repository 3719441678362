import React from 'react';
import {SkeletonLoader} from './index';

export default function CardSummary({
  img = '',
  title = '',
  body = 0,
  className = '',
  loading = false,
}) {
  const transformedBody =
    typeof Number(body) === 'number' && !isNaN(body)
      ? Number(body).toLocaleString()
      : body;
  return (
    <article
      className={`flex items-center flex-none gap-6 px-4 py-6 rounded-lg md:w-full md:flex-1 md:p-6 bg-white md:bg-white80 ${className}`}
    >
      <img src={img} alt="" width={40} height={40} />
      <div className="flex flex-col">
        <span className="text-sm font-medium text-body">{title}</span>
        <span className="font-semibold text-xxl text-grey">
          {loading ? (
            <div className="mt-2">
              <SkeletonLoader
                style={{width: '100%', height: 20, maxWidth: 100}}
              />
            </div>
          ) : (
            transformedBody
          )}
        </span>
      </div>
    </article>
  );
}
