import React, {useCallback, useEffect, useState, useMemo, memo} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {logoutIc, logoutIcGrey} from '../assets/images/images';
import * as Actions from '../store/actions';

import {useMediaQuery} from '../hooks';
import {Constants} from '../helpers';
import {MainSideNavigations} from './SideNavigations/MainSideNavigations';
import {ReconciloSideNavigations} from './SideNavigations/ReconciloSideNavigations';
import {usePermissions} from '../hooks/useSidbarPermissions';
import {version} from '../version';

const isReconcilo = localStorage?.getItem('subdomain')?.includes('reconcilo');
const SubSidebar = memo(() => {
  const dispatch = useDispatch();
  const {pathname} = useLocation();
  const mediaQuery = useMediaQuery('(min-width: 1024px)');

  const media = useMemo(() => mediaQuery, [mediaQuery]);
  const [openDropdown, setOpenDropdown] = useState(null);
  const toggleSubRoute = useCallback(routeKey => {
    setOpenDropdown(prevDropdown =>
      prevDropdown === routeKey ? null : routeKey,
    );
  }, []);
  useEffect(() => {
    if (openDropdown !== null) {
      sessionStorage.setItem(
        'open_dropdown_item',
        JSON.stringify(openDropdown),
      );
    }
  }, [openDropdown]);
  useEffect(() => {
    const savedOpenDropdown = sessionStorage.getItem('open_dropdown_item');
    setOpenDropdown(savedOpenDropdown ? savedOpenDropdown : null);
  }, []);

  const splitLocation = pathname.split('/');
  const environment = {production: 'P', development: 'D', staging: 'S'}[
    process.env.REACT_APP_APP_ENV_MODE
  ];

  return (
    <ul className="xl:px-[18px] mt-6 lg:mt-0">
      {!isReconcilo ? (
        <MainSideNavigations
          media={media}
          openDropdown={openDropdown}
          pathname={pathname}
          splitLocation={splitLocation}
          toggleSubRoute={toggleSubRoute}
        />
      ) : (
        <ReconciloSideNavigations
          media={media}
          openDropdown={openDropdown}
          pathname={pathname}
          splitLocation={splitLocation}
          toggleSubRoute={toggleSubRoute}
        />
      )}

      <button
        onClick={() => dispatch(Actions?.logout())}
        className="mt-6 lg:mt-[54px] w-full rounded text-white flex items-center gap-2 pl-4 py-2 lg:hovhover:bg-brandBlue40 er:bg-[#1c1d31]"
      >
        <img
          src={media ? logoutIc : logoutIcGrey}
          alt=""
          width={18}
          height={18}
        />
        <span className="-mb-1 text-sm text-grey lg:text-white">Sign out</span>
      </button>
      {/*{process.env.REACT_APP_APP_ENV_MODE === 'isaac-local-env' && (*/}
      {/*)}*/}
      <div className="w-full rounded text-white flex items-center gap-2 pl-4 py-2 lg:hovhover:bg-brandBlue40 er:bg-[#1c1d31]">
        <p className="text-xs text-grey30">
          v-{version} - {environment || 'D'}
        </p>
      </div>
    </ul>
  );
});

const SubDomainSettingsLayout = () => {
  const userDetails = useSelector(state => state?.auth?.userDetails);
  const {
    modularisation: {
      audit_logs_services,
      billings_services,
      profile_services,
      teams_services,
    },
  } = usePermissions();

  const USER_PERMISSIONS = useMemo(
    () => userDetails?.permissions,
    [userDetails],
  );
  return (
    <div className="mt-6 md:mt-[27px] border-b border-grey60">
      <div className="flex items-center gap-4 overflow-x-auto settings-nav whitespace-nowrap">
        {profile_services && (
          <NavLink
            end
            to={Constants.PAGES_URL.SETTINGS_PROFILE}
            className={({isActive}) =>
              `font-medium text-sm py-2 ${
                isActive
                  ? 'text-brandBlue border-b-2 border-brandBlue'
                  : 'text-grey30'
              }`
            }
          >
            Profile
          </NavLink>
        )}
        {(USER_PERMISSIONS?.role === 'Owner' ||
          Object.values(USER_PERMISSIONS?.settingsPermissions || {})?.some(
            p => !!p,
          )) && (
          <>
            {USER_PERMISSIONS?.settingsPermissions?.manageMembers &&
              teams_services &&
              !isReconcilo && (
                <NavLink
                  to={`/settings/team`}
                  className={({isActive}) =>
                    `font-medium text-sm py-2 ${
                      isActive
                        ? 'text-brandBlue border-b-2 border-brandBlue'
                        : 'text-grey30'
                    }`
                  }
                >
                  Team
                </NavLink>
              )}
            {audit_logs_services && (
              <NavLink
                to={`/settings/audit-logs`}
                className={({isActive}) =>
                  `font-medium text-sm py-2 ${
                    isActive
                      ? 'text-brandBlue border-b-2 border-brandBlue'
                      : 'text-grey30'
                  }`
                }
                style={{
                  pointerEvents: USER_PERMISSIONS?.settingsPermissions
                    ?.viewAndDownloadAudit
                    ? 'auto'
                    : 'none',
                }}
              >
                Audit Logs
              </NavLink>
            )}
            {billings_services && !isReconcilo && (
              <NavLink
                to={`/settings/billing`}
                className={({isActive}) =>
                  `font-medium text-sm py-2 ${
                    isActive
                      ? 'text-brandBlue border-b-2 border-brandBlue'
                      : 'text-grey30'
                  }`
                }
              >
                Billing
              </NavLink>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export {SubSidebar, SubDomainSettingsLayout};
