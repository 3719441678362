import React, {useContext} from 'react';
import PuffLoader from 'react-spinners/PuffLoader';
import {css} from '@emotion/react';
import {CheckboxContext} from '../helpers/context';
import {toast} from 'react-toastify';

export default function SecondaryButton({
  buttonText,
  xPadding = 'px-4',
  yPadding = 'pt-[14px] pb-[14px]',
  clearFilters,
  className = '',
  onClick,
  loading,
  fontSize = 'text-sm',
  src,
  alt,
  width,
  height,
  btnHeight = 'h-[46px]',
  appFilters,
  imgClass,
  leftImg,
  noOutline,
  color,
  loadColor = 'var(--color-primary)',
  ...props
}) {
  const override = css`
    border-color: #ffffff;
    position: absolute;
    background: transparent;
  `;

  const {setIsChecked} = useContext(CheckboxContext);

  const handleClearFilters = () => {
    if (Object.values(appFilters).filter(Boolean).length !== 0) {
      onClick && onClick();
      setIsChecked({});
      toast.success('Filters cleared');
    } else {
      onClick?.();
    }
  };

  return (
    <button
      type="button"
      onClick={() => {
        clearFilters ? handleClearFilters() : onClick?.();
      }}
      className={` ${className}  ${
        loading ? `flex items-center justify-center relative ${btnHeight}` : ''
      } ${btnHeight}  ${
        !noOutline
          ? 'outline outline-1 outline-grey80 text-[#0D192C] hover:bg-[#f3f3f3] duration-200 transition focus-visible:outline-2 bg-white80'
          : ''
      }  ${fontSize} font-inter font-medium duration-200 transition rounded ${xPadding} ${yPadding}`}
      {...props}
    >
      {loading ? <PuffLoader css={override} size={35} color="#ffffff" /> : null}

      {src && leftImg ? (
        <img
          src={src}
          alt={alt}
          width={width}
          height={height}
          className={imgClass}
        />
      ) : null}

      {loading ? (
        <PuffLoader
          css={override}
          size={35}
          color={loadColor || color || '#ffffff'}
        />
      ) : null}
      <p>{buttonText}</p>
      {src && !leftImg ? (
        <img
          src={src}
          alt={alt}
          width={width}
          height={height}
          className={imgClass}
        />
      ) : null}
    </button>
  );
}
