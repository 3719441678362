import React from 'react';
import {Loader, Modal} from '../../../../components';
import {corruptedFile, smiley} from '../../../../assets/images/images';
import {Link} from 'react-router-dom';

export const CompanySearchModal = ({
  open,
  setOpen,
  changeLookupPage,
  loading,
  lookup,
}) => (
  <Modal
    extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
    modalPosition="justify-center sm:justify-end"
    modalTitle={
      <div className="flex items-center gap-1">
        <h3 className="text-base font-medium text-grey">
          {lookup?.company_search?.name}
        </h3>
        <span className="w-5 h-5 bg-brandBlue flex items-center justify-center rounded-full text-white text-xs font-medium">
          {lookup?.company_search?.detail?.length || 0}
        </span>
      </div>
    }
    show={open}
    onClose={() => {
      setOpen(false);
      changeLookupPage(1);
    }}
    setOpen={setOpen}
  >
    {loading ? (
      <Loader height={25} />
    ) : (
      <div className="mt-6 flex flex-col gap-4">
        {lookup?.company_search?.detail?.length > 0 ? (
          lookup?.company_search?.detail?.map((result, index) => (
            <Link
              to={`/easy-lookup/business/global-search/result/${result.internationalNumber}`}
              key={index}
              className="p-6 bg-white80 rounded flex items-center justify-between w-full card-link"
              onClick={() => {
                setOpen(false);
                changeLookupPage(1);
              }}
            >
              <div className="flex items-center gap-2">
                <img src={smiley} alt="" width={24} height={24} />
                <p className="text-base text-grey ">
                  {result.name || result?.Name}
                </p>
              </div>
              <p className="text-sm text-grey pr-4">
                {result.match_type || result?.NameMatchScore}
              </p>
            </Link>
          ))
        ) : (
          <div className="p-4  w-full ">
            <div className="flex flex-col items-center justify-center py-12 mt-6 bg-white80">
              <img src={corruptedFile} alt="" width={48} height={48} />
              <p className="max-w-[179px] mt-2 text-sm text-center text-body">
                We did not find any information on this company
              </p>
            </div>
          </div>
        )}
      </div>
    )}
  </Modal>
);
