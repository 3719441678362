import React from 'react';
import {Modal, PrimaryButton, SkeletonLoader} from '../../../../components';
import {placeholder, copyWhite} from '../../../../assets/images/images';
import {toast} from 'react-toastify';

function ScanQRCodeModal({open, setOpen, setOTPOpen, isLoading, _2FAData}) {
  const copyText = text => {
    navigator.clipboard.writeText(text);
    toast.success('Secret copied');
  };

  return (
    <Modal
      show={open}
      onClose={setOpen}
      extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
      modalTitle="Setup 2FA - Scan QR code"
    >
      <section className="mt-10 text-left">
        <h2 className="mb-1 text-body">Setup 2 Factor Authentication (2FA)</h2>
        <p className="text-[11px] text-grey-500 font-thin">
          Dojah can help you onboard users and perform KYC more efficiently at
          the same time
        </p>

        <div className="my-8 flex justify-center items-center flex-col gap-5 border border-grey5 p-10 rounded-lg">
          {isLoading ? (
            <SkeletonLoader
              style={{
                height: 300,
              }}
              className="before:h-full"
            />
          ) : (
            <img
              src={_2FAData?.qrCode || placeholder}
              alt=""
              className={`${_2FAData?.qrCode && 'h-[300px]'}`}
            />
          )}
          <h2 className="w-[200px] text-center">
            Scan QR code with your authenticator app
          </h2>
        </div>
        <p className="text-center">Or enter manually</p>

        <div className="my-12">
          <p className=" text-sm text-grey-500 ">Enter manually</p>
          <div className="relative  my-6">
            <p className="text-body pl-6 relative text-[14px]  overflow-auto  ">
              {(_2FAData?.secret &&
                `${_2FAData?.secret?.substring(0, 20)}...`) ||
                'Secret will appear here...'}
            </p>
            <button
              onClick={() => copyText(_2FAData?.secret)}
              className="absolute flex items-center gap-2 p-2 -translate-y-1/2 border rounded top-2/4 right-4 bg-brandBlue"
            >
              <img src={copyWhite} alt="" width={14} height={14} />
              <span className="text-sm font-medium text-white">Copy</span>
            </button>
          </div>
        </div>

        <PrimaryButton
          onClick={setOTPOpen}
          buttonText="Continue"
          className="w-full mt-6"
          disabled={!_2FAData}
        />
      </section>
    </Modal>
  );
}

export {ScanQRCodeModal};
