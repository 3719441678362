import {toast} from 'react-toastify';
import {api, onSignupSuccessHelper, token} from '../../helpers';

export function signUp(userData) {
  return api.post('/users/signup/', {...userData}).then(res => res.data);
}

export function addCompany(companyData) {
  return api
    .post('/users/signup/complete', {...companyData})
    .then(res => res.data);
}
export function updateUserProfile(userData) {
  return api.put('/users/user', {...userData}).then(res => res.data);
}

export function joinCompany(data) {
  return api.post('/users/company/join', data).then(res => res.data);
}
export function checkInviteLink(token) {
  return api.get(`/users/company/join/${token}`).then(res => res.data);
}
export function requestNewLink(data) {
  return api.post('/users/company/invite/resend', data).then(res => res.data);
}

export function getCompanyName(token) {
  return api.get(`/users/company/join/${token}`).then(res => res.data);
}

export function login(userData) {
  return api.post('/users/login', {...userData}).then(res => res.data);
}

export function googleSignin() {
  return api.get('/users/login/google').then(res => res.data);
}

export function completeGoogleSignin(code, dispatch, navigate) {
  return api
    .post('/users/login/google/complete', {
      code,
    })
    .then(res => {
      if (!res?.data?.two_factor_auth)
        onSignupSuccessHelper(res.data, dispatch, navigate);

      return res.data;
    })
    .catch(err => {
      if (err) {
        if (
          err.response.data.error === 'Account does not exist' &&
          err.response.status === 404
        ) {
          toast.error(err.response.data.error);
          return setTimeout(() => {
            window.location.href = '/account-not-found';
          }, 2000);
        } else if (err.response.status !== 500) {
          toast.error(err.response.data.error);
          window.location.href = '/signin';
        } else {
          window.location.href = '/signin';
        }
      }
    });
}

export function forgotPassword(data) {
  return api.put('users/password/reset', {...data}).then(res => res.data);
}
export function confirmPassword(data) {
  return api
    .put('users/password/reset/confirm', {...data})
    .then(res => res.data);
}

export function logOut() {
  token.clear();
  window.location.reload();
}
